module.exports = {
  linkResolver({ type, uid }) {
    let url;

    switch (type) {
      case 'home_page_-_general':
        url = '/';
        break;
      case 'about_us':
        url = `/about`;
        break;
      case 'careers':
        url = `/careers`;
        break;
      case 'cityworks':
        url = `/cityworks`;
        break;
      case 'supplier_diversity':
        url = `/supplier-diversity`;
        break;
      case 'contact':
        url = `/contact`;
        break;
      case 'legal':
        url = `/terms`;
        break;
      case 'library':
        url = `/library`;
        break;
      case 'locations':
        url = `/locations`;
        break;
      case 'market':
        url = `/${uid}/`;
        break;
      case 'newsletter':
        url = `/newsletters/${uid}/`;
        break;
      case 'power360':
        url = `/power360`;
        break;
      case 'publication':
        url = `/library/${uid}/`;
        break;
      case 'services_-_landing_page':
        url = `/services`;
        break;
      case 'service_area':
        url = `/services/${uid}/`;
        break;
      default:
        url = '/404';
        break;
    }

    return url;
  },
};
