module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHB3VF9","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wwwpowerengcom","defaultLang":"en-us","accessToken":"MC5YUlZoZ0JBQUFDVUFwdFlV.77-9de-_ve-_ve-_vU7vv70G77-9UO-_ve-_ve-_vUVgMjXvv73vv71877-9du-_ve-_vWsPRu-_ve-_vTvvv71N","previews":true,"path":"/preview","pages":[{"type":"Service_area","match":"/services/:uid","path":"/service-area-preview","component":"/opt/build/repo/src/templates/service-area.js"},{"type":"Publication","match":"/library/:uid","path":"/publication-preview","component":"/opt/build/repo/src/templates/publication.js"},{"type":"Newsletter","match":"/newsletters/:uid","path":"/newsletter-preview","component":"/opt/build/repo/src/templates/newsletter.js"},{"type":"Market","match":"/:uid","path":"/market-preview","component":"/opt/build/repo/src/templates/market.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
